.user-add-modal {
  .ant-modal-header {
    .ant-modal-title {
      display: flex;
      align-items: center;
      font-family: var(--primary-font);
      font-weight: var(--font-weight-medium);
      color: var(--primary-color-dark);
      font-size: 14px;
      .anticon {
        font-size: 24px;
        margin-right: 10px;
      }
    }
  }
  .ant-modal-footer {
    border-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .ant-btn {
      width: 35%;
    }
  }
  .ant-modal-close-icon {
    color: var(--primary-color-dark);
    font-size: 18px;
  }
  .user-add-form-input {
    width: 100%;
  }
}
