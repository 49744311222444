.unit-records-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2% 12%;
  .unit-data-container {
    width: 100%;
    flex-grow: 1;
    overflow: auto;
    margin-bottom: 5%;
    padding: 10px;
  }
  .unit-record-history-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .ant-btn {
      width: 45%;
    }
  }
}
