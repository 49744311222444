.profile-container {
  height: 100%;
  padding: 3%;
  .profile-content {
    padding-left: 20%;
    padding-right: 20%;
    .profile-action-button {
      margin-top: 16px;
      width: 25%;
      align-self: flex-end;
      .ant-upload.ant-upload-select {
        width: 100%;
      }
    }
    .profile-form {
      display: flex;
      flex-direction: column;
      .form-title {
        font-size: 14px;
        font-family: var(--primary-font);
        font-weight: var(--font-weight-medium);
        margin-bottom: 10px;
      }
      .change-password {
        width: 100%;
        margin-top: -25px;
        text-align: right;
        .ant-btn-link {
          padding-right: 0;
        }
      }
    }
  }
}
