.see-more-drawer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .see-more-drawer-title {
    font-family: var(--primary-font);
    font-weight: var(--font-weight-semi-bold);
    color: var(--primary-color-dark);
    font-size: 16px;
  }
}
