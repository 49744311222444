@mixin pagination($siblingClass: '') {
  #{$siblingClass}.ant-pagination {
    margin-top: 0;
    .ant-pagination-item-link,
    .ant-pagination-item {
      border: none;
      color: var(--dark-color);
      background: inherit;
    }
    .ant-pagination-item-link {
      font-size: 14px;
      &:hover,
      &:focus {
        color: var(--primary-color);
      }
      &[disabled] {
        opacity: 0.4;
      }
    }
    .ant-pagination-item {
      font-family: var(--secondary-font);
      font-size: 14px;
      a {
        color: var(--dark-color);
      }
      &:hover a,
      &:focus a {
        color: var(--primary-color);
      }
      &.ant-pagination-item-active {
        border: none;
        a {
          color: var(--primary-color);
          font-weight: var(--font-weight-medium);
        }
      }
    }
  }
}
