.admin-cards-container {
  width: 90%;
  margin-top: 39px;
  .admin-card {
    margin-bottom: 20px;
    .admin-card-text {
      margin: 0px 20px;
      .admin-card-quantity {
        font-size: 30px;
        font-family: var(--secondary-font);
        font-weight: var(--font-weight-medium);
        color: var(--dark-color);
      }
      .admin-card-description {
        font-family: var(--primary-font);
        font-weight: var(--font-weight-regular);
        .admin-card-description-strong {
          font-weight: var(--font-weight-medium);
        }
      }
    }
  }
}
