.ant-spin.units-add-form-loading {
  padding-top: 5px;
  width: 100%;
}
.units-add-form {
  .ant-form-item {
    display: block;
  }
  .units-add-form-radio {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: space-between;
    .ant-radio-wrapper {
      margin-right: 0;
    }
  }
  .units-add-form-input {
    width: 100%;
  }
  .units-add-form-footer {
    margin-top: 10%;
    .units-add-form-button {
      width: 100%;
    }
  }
}
