@import '~styles/common/_mixinPagination';

.finca-table {
  width: 100%;
  .ant-table {
    background-color: transparent;
    table {
      border-spacing: 0px 10px;
    }
    .ant-checkbox-wrapper {
      border-spacing: 0;
    }
    .ant-table-cell {
      color: var(--dark-color);
    }
    .ant-table-thead {
      .ant-table-cell {
        font-family: var(--primary-font);
        font-weight: var(--font-weight-medium);
        font-size: 12px;
        background: var(--light-color);
        border-bottom: none;
        padding: 0px 16px;
        .ant-table-column-sorters {
          padding: 0px;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-row {
        background: var(--clear-color);
        box-shadow: 0px 0px 4px var(--shadow-color);
        .ant-table-cell {
          font-family: var(--secondary-font);
          font-weight: var(--font-weight-regular);
          font-size: 12px;
          border-bottom: none;
          padding-bottom: 12px;
          padding-top: 12px;
        }
        &.bold {
          .ant-table-cell {
            font-weight: var(--font-weight-bold);
          }
        }
      }
    }
    .ant-table-footer {
      text-align: center;
      padding: 0;
      background-color: transparent;
      color: var(--dark-color);
      margin-bottom: 8px;
    }
  }
  @include pagination('.ant-table-pagination');
}
