.unit-show-drawer {
  .ant-drawer-header {
    .ant-drawer-title {
      display: flex;
      align-items: center;
      font-family: var(--primary-font);
      font-weight: var(--font-weight-medium);
      color: var(--primary-color-dark);
      font-size: 14px;
      .anticon {
        font-size: 24px;
        margin-right: 10px;
      }
    }
    .ant-drawer-close {
      color: var(--primary-color-dark);
      font-size: 18px;
    }
  }
  .ant-drawer-body {
    padding: 0;
    .ant-spin {
      padding-top: 5px;
      width: 100%;
    }
  }
}

.unit-show-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1% 5% 3% 5%;
  .unit-title {
    text-align: left;
    margin-bottom: 5px;
    font-family: var(--primary-font);
    font-weight: var(--font-weight-bold);
    font-size: 12px;
    &:not(:first-child) {
      margin-top: 2%;
    }
  }
  .unit-data-container {
    flex-grow: 1;
    overflow: auto;
    margin: 1% 0;
    padding: 0 10px;
  }
  .unit-buttons {
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .unit-button {
      width: 25%;
      margin: 0 2%;
    }
  }
}
