.insumos-cards-container {
  width: 90%;
  margin-top: 39px;
  .ant-list-item {
    padding: 0;
  }
  .insumos-card {
    margin-bottom: 20px;
    .insumos-card-text {
      margin: 0px 20px;
      text-transform: capitalize;
      .insumos-card-quantity {
        font-size: 30px;
        font-family: var(--secondary-font);
        font-weight: var(--font-weight-medium);
        color: var(--dark-color);
      }
      .insumos-card-description {
        font-family: var(--primary-font);
        font-weight: var(--font-weight-regular);
        .insumos-card-description-strong {
          font-weight: var(--font-weight-medium);
        }
      }
    }
  }
}
