.ant-carousel > .slick-slider.units-card-carousel {
  .slick-arrow {
    &.units-card-carousel-arrow {
      color: var(--primary-color);
      font-size: 15px;
      &:hover,
      :focus {
        color: var(--primary-color);
      }
    }
    &.slick-next {
      right: -10px;
    }
    &.slick-prev {
      left: -10px;
    }
  }
  .unit-carousel-kpi {
    margin-left: 16px;
  }
}
