.left-menu-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 28px;
  .left-menu-list-item {
    font-size: 18px;
    font-family: var(--primary-font);
    font-weight: var(--font-weight-regular);
    a {
      color: var(--primary-color-dark);
      &.selected,
      &:hover {
        text-shadow: 0 0 1px var(--primary-color-dark);
      }
    }
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
