.dashboard-top-bar-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: var(--primary-font);
  font-weight: var(--font-weight-semi-bold);
  color: var(--primary-color-dark);
  .market-data {
    .market-data-value-container {
      font-family: var(--secondary-font);
      font-weight: var(--font-weight-medium);
      display: flex;
      justify-content: center;
      align-items: center;
      .market-data-icon {
        height: 20px;
        margin-right: 8px;
      }
    }
  }
}
