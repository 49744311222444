.app-submenu-layout {
  .app-submenu-header {
    position: relative;
    height: 60px;
    padding: 25px 2%;
    background-color: var(--clear-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 5px 6px var(--shadow-color);
  }
  .app-submenu-content {
    background-color: var(--light-color);
    height: 100%;
    padding: 3%;
  }
}
