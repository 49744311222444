.license-card {
  font-size: 12px;
  font-family: var(--primary-font);
  font-weight: var(--font-weight-medium);
  color: var(--primary-color-dark);
  .license-info-line:not(:last-child) {
    margin-bottom: 15px;
  }
  .license-info {
    font-size: 14px;
    font-family: var(--secondary-font);
    font-weight: var(--font-weight-regular);
    color: var(--dark-color);
  }
  .license-expiration {
    text-align: center;
    .license-expiration-date {
      font-size: 10px;
      font-family: var(--secondary-font);
    }
  }
}
