.upload-logo-card {
  display: flex;
  flex-direction: column;
  .upload-logo-container {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .upload-logo-img {
      height: 60px;
      width: 150px;
      border: 1px solid var(--primary-color-dark);
      border-radius: 5px;
      padding: 1px;
    }
    .upload-logo-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      width: 250px;
      background-color: var(--primary-color-light);
      border: 2px dashed var(--primary-color-dark);
    }
  }
}
