.finca-modal-title {
  display: flex;
  align-items: center;
  font-family: var(--primary-font);
  font-weight: var(--font-weight-medium);
  color: var(--primary-color-dark);
  font-size: 14px;
  .anticon {
    font-size: 24px;
    margin-right: 10px;
  }
}
