.filter-sider-form {
  .ant-collapse .ant-collapse-item {
    .ant-collapse-header {
      padding: 12px 0px;
    }
    .ant-collapse-content-box {
      padding: 12px 0px;
    }
  }
}
