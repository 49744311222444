.finca-screen-header {
  .finca-screen-header-container {
    width: 100%;
    .finca-screen-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .finca-screen-divider {
    width: 100%;
    height: 4px;
    margin-top: 10px;
    background-color: var(--primary-color);
    opacity: 0.2;
    border-radius: 8px;
  }
}
