@import '../../../../styles/common/mixinListSimple';

.dashboard-core-list-header {
  padding: 10px 7% 0;
  display: flex;
  justify-content: flex-end;
  .header {
    font-family: var(--primary-font);
    font-weight: var(--font-weight-medium);
    color: var(--primary-color);
    font-size: 12px;
    text-align: right;
    width: 18%;
  }
}

.dashboard-core-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include list-simple;
  .dashboard-core-quantity,
  .dashboard-core-extra {
    text-align: right;
    width: 18%;
    &.total-row {
      font-weight: var(--font-weight-medium);
    }
  }
  .dashboard-core-title {
    flex-grow: 1;
    &.total-row {
      font-weight: var(--font-weight-medium);
    }
  }
  .percentage-text {
    text-align: right;
    font-size: 14px;
    width: 12%;
  }
}
