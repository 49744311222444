.superficie-card {
  display: flex;
  flex-direction: column;
  .superficie-card-body {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--dark-color);
    .ant-space-item {
      text-align: center;
    }
    .superficie-value {
      font-size: 24px;
      font-family: var(--secondary-font);
    }
  }
}
