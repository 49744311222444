.adquisicion-add-modal {
  .ant-modal-footer {
    border-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .ant-btn {
      width: 35%;
    }
  }
}
