.welcome-user {
  font-size: 14px;
  font-family: var(--primary-font);
  font-weight: var(--font-weight-regular);
  color: var(--dark-color);
  .welcome-user-company {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-family: var(--secondary-font);
    color: var(--primary-color-dark);
    .welcome-user-icon {
      margin-right: 5px;
      height: 18px;
    }
  }
  .welcome-user-name {
    font-size: 30px;
    font-family: var(--primary-font);
    span {
      font-weight: var(--font-weight-semi-bold);
    }
  }
}
