.ant-carousel > .user-carousel {
  .slick-arrow.user-carousel-arrow {
    color: var(--primary-color);
    background-color: var(--clear-color);
    border-radius: 50%;
    font-size: 30px;
    &:hover,
    :focus {
      color: var(--primary-color);
    }
  }
  .slick-list {
    padding: 5px;
  }
}

.user-card {
  padding: 20px 25px;
  position: relative;
  font-size: 14px;
  font-family: var(--primary-font);
  color: var(--dark-color);
  margin-left: 10px;
  margin-right: 10px;
  .user-actions {
    position: absolute;
    top: 0;
    right: 0;
  }
  .user-header {
    display: flex;
    align-items: center;
  }
  .user-avatar {
    color: var(--light-color);
    background: var(--primary-color);
  }
  .user-name {
    flex: 1;
    margin-left: 3%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: var(--font-weight-medium);
    font-family: var(--primary-font);
    color: var(--dark-color);
  }
  .user-email {
    margin-top: 5%;
    font-size: 12px;
    font-family: var(--primary-font);
    color: var(--dark-color);
    overflow-wrap: break-word;
  }
}
