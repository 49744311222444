.finca-card {
  background-color: var(--clear-color);
  box-shadow: 0px 0px 8px var(--shadow-color);
  padding: 15px;
  .finca-card-font {
    font-family: var(--primary-font);
    font-weight: var(--font-weight-medium);
    color: var(--dark-color);
  }
  .finca-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    .finca-card-title {
      font-size: 16px;
      padding-right: 21px;
      &[data-type='secondary'] {
        color: var(--primary-color-dark);
      }
    }
  }
  .finca-card-quantity {
    font-family: var(--secondary-font);
    font-size: 30px;
  }
  .finca-card-description {
    font-weight: var(--font-weight-regular);
    font-size: 14px;
  }
  .finca-card-tooltip {
    position: absolute;
    top: 15px;
    right: 30px;
    font-size: 20px;
    color: var(--primary-color-dark);
  }
  .go-to-icon {
    font-size: 20px;
  }
  &[data-withicon='true'] {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &[data-hoverable='true'] {
    &:hover {
      cursor: pointer;
    }
  }
}
