.finca-title {
  font-family: var(--primary-font);
  font-weight: var(--font-weight-bold);
  color: var(--primary-color-dark);
  &[data-level='1'] {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: var(--font-weight-regular);
    color: var(--primary-color);
    font-size: 24px;
    line-height: 28px;
  }
  &[data-level='2'] {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: var(--font-weight-regular);
    color: var(--dark-color);
    font-size: 20px;
    line-height: 23px;
  }
  &[data-level='3'] {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: var(--font-weight-medium);
    font-size: 16px;
  }
}
