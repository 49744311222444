.finca-delete-modal {
  .finca-delete-modal-body {
    display: flex;
    justify-content: center;
    font-family: var(--secondary-font);
    font-size: 14px;
    color: var(--dark-color);
    text-align: center;
  }
}
