@mixin list-simple {
  .ant-list-items {
    .ant-list-item {
      border-bottom: 1px solid var(--primary-color);
      padding: 5px 7%;
      font-size: 16px;
      font-family: var(--secondary-font);
      font-weight: var(--font-weight-regular);
      color: var(--dark-color);
      justify-content: flex-start;
      &:last-child {
        border: 0px;
      }
      span {
        display: inline-block;
        &.highlight {
          font-weight: var(--font-weight-medium);
        }
      }
    }
  }
}
