.management-kpi-card {
  color: var(--dark-color);
  font-family: var(--secondary-font);
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-space-item {
    text-align: center;
  }
  .management-kpi-value {
    font-size: 30px;
  }
  .management-kpi-title {
    font-size: 16px;
  }
}
