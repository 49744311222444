.est-license-card {
  background-color: var(--secondary-color-light);
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .est-license-action {
    position: absolute;
    top: 5px;
    right: 10px;
    .action-icon {
      font-size: 24px;
      color: var(--primary-color);
    }
  }
  .est-license-type {
    width: 100%;
    padding: 10px;
  }
  .est-license-date {
    width: 50%;
    padding: 10px;
  }
  .est-license-title {
    font-size: 10px;
    font-family: var(--primary-font);
    font-weight: var(--font-weight-medium);
    color: var(--primary-color-dark);
  }
  .est-license-text {
    font-size: 14px;
    font-family: var(--secondary-font);
    color: var(--dark-color);
  }
}
