.unit-header {
  width: 100%;
  height: 100px;
  padding: 0 10px;
  display: flex;
  .unit-header-avatar {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .unit-header-info {
    flex: 6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .unit-header-title {
      font-family: var(--primary-font);
      font-weight: var(--font-weight-medium);
      color: var(--primary-color-dark);
      font-size: 18px;
    }
    .unit-header-subtitle {
      font-family: var(--secondary-font);
      color: var(--dark-color);
      font-size: 14px;
    }
    .unit-tags {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      margin-top: 5px;
      .unit-tag {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 10px;
        color: var(--light-color);
        background-color: var(--primary-color);
      }
    }
  }
}

.unit-info {
  box-shadow: 0px 3px 10px var(--shadow-color);
  padding: 15px 15px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  .unit-info-column {
    display: flex;
    flex-direction: column;
    width: 33%;
    margin-right: 8px;
    .unit-info-section {
      margin-bottom: 18px;
    }
  }
  .unit-info-value {
    span {
      display: inline-block;
      width: 50%;
      word-wrap: normal;
      vertical-align: top;
      font-family: var(--secondary-font);
      font-size: 14px;
      color: var(--dark-color);
    }
    span:first-child {
      width: 45%;
      font-family: var(--primary-font);
      font-weight: var(--font-weight-medium);
      font-size: 12px;
      color: var(--primary-color-dark);
    }
  }
}
