.unit-tree-graph {
  border: 1px dotted var(--primary-color-dark);
  width: 100%;
  height: 350px;
  overflow: hidden;
  .rd3t-svg {
    .rd3t-link {
      stroke: var(--primary-color);
      stroke-width: 2px;
      stroke-opacity: 0.5;
    }
    .unit-tree-node-circle {
      stroke: var(--primary-color-dark);
      stroke-width: 2px;
      fill: var(--primary-color-light);
    }
    .unit-tree-node-group {
      .unit-tree-node-label {
        fill: var(--opaque-color);
        stroke: none;
        font-weight: bolder;
      }
      .unit-tree-node-description {
        fill: #aaaaaa;
        stroke: none;
        font-weight: bolder;
      }
    }
  }
}
