.ant-spin.units-add-form-loading {
  padding-top: 5px;
  width: 100%;
}
.est-add-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  .ant-form-item {
    display: block;
  }
  .est-add-form-input {
    width: 100%;
  }
  .est-add-form-footer {
    flex: 1;
    display: flex;
    align-items: flex-end;
    .est-add-form-button {
      width: 100%;
    }
  }
}
