.unit-record-card {
  box-shadow: 0px 0px 4px var(--shadow-color);
  .ant-spin-container {
    padding: 5%;
  }
  .unit-record-actions {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
  }
  .unit-record-card-header {
    display: flex;
    justify-content: space-between;
    .unit-record-card-event {
      font-family: var(--primary-font);
      font-weight: var(--font-weight-medium);
      color: var(--dark-color);
      font-size: 18px;
    }
    .unit-record-card-date {
      font-family: var(--secondary-font);
      font-weight: var(--font-weight-medium);
      color: var(--dark-color);
      font-size: 20px;
    }
  }
  .unit-record-card-body {
    margin-top: 3%;
    font-family: var(--secondary-font);
    font-weight: var(--font-weight-medium);
    color: var(--dark-color);
    font-size: 14px;
  }
}
