.unit-obs {
  margin-bottom: 3%;
  .add-obs-button {
    font-family: var(--primary-font);
    font-weight: var(--font-weight-medium);
    font-size: 10px;
    color: var(--primary-color-dark);
    padding-left: 0;
    span {
      text-decoration: underline;
    }
  }
}

.observation-main {
  position: relative;
  text-align: left;
  width: 100%;
  padding: 15px;
  box-shadow: 0px 3px 10px var(--shadow-color);
  margin-bottom: 2%;
  .observation-delete-buttons {
    position: absolute;
    right: 5px;
    top: 10px;
    .observation-del-btn-delete,
    .observation-del-btn-confirm,
    .observation-del-btn-cancel {
      font-family: var(--primary-font);
      font-weight: var(--font-weight-medium);
      font-size: 10px;
      span {
        text-decoration: underline;
      }
    }
    .observation-del-btn-delete {
      color: var(--primary-color-dark);
    }
    .observation-del-btn-cancel {
      color: var(--dark-color);
    }
  }
  .observation-title {
    font-family: var(--secondary-font);
    font-size: 12px;
    color: var(--primary-color);
  }
  .observation-body {
    font-family: var(--secondary-font);
    font-size: 12px;
    color: var(--dark-color);
  }
}

.observation-add {
  width: 100%;
  margin-top: 2%;
  .observation-container .ant-spin-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .observation-content {
      width: 100%;
      background-color: var(--clear-color);
      font-family: var(--secondary-font);
      font-size: 12px;
    }
    .observation-add-buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .observation-add-btn-cancel,
    .observation-add-btn-save {
      font-family: var(--primary-font);
      font-weight: var(--font-weight-medium);
      font-size: 10px;
      color: var(--primary-color-dark);
      span {
        text-decoration: underline;
      }
    }
    .observation-add-btn-cancel {
      color: var(--dark-color);
    }
  }
}
