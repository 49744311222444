.app-layout {
  &.ant-layout {
    min-height: 100vh;
  }
  .app-content-layout {
    .app-header {
      height: var(--app-header-height);
      padding: 25px 2% 15px;
      background-color: var(--secondary-color);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .app-header-logo {
        color: var(--primary-color-dark);
        height: 48px;
      }
      .app-header-navigation {
        display: flex;
        align-items: center;
        .navigation-list {
          list-style-type: none;
          margin: 0;
          padding: 0;
          .navigation-list-item {
            display: inline;
            font-size: 18px;
            font-family: var(--primary-font);
            font-weight: var(--font-weight-regular);
            margin-right: 40px;
            a {
              color: var(--primary-color);
              &.selected,
              &:hover {
                text-shadow: 0 0 1px var(--primary-color);
              }
            }
          }
        }
        .app-header-icon-item {
          color: var(--primary-color);
          height: 25px;
          font-size: 25px;
          &:not(:last-child) {
            margin-right: 24px;
          }
          &:hover {
            cursor: pointer;
            color: var(--primary-color-dark);
          }
        }
        .app-header-dropdown-img {
          height: 40px;
          width: 40px;
          border: 1px solid var(--primary-color-dark);
          border-radius: 50%;
          &:hover {
            cursor: pointer;
            opacity: 0.8;
            border-color: var(--primary-color);
          }
        }
      }
    }
    .app-content {
      background-color: var(--light-color);
    }
    .app-footer {
      height: var(--app-footer-height);
      padding: 5px 100px;
      font-size: 10px;
      background-color: var(--secondary-color-dark);
      display: flex;
      justify-content: space-between;
      .app-footer-text {
        font-family: var(--secondary-font);
        color: var(--primary-color-dark);
      }
    }
  }
}

.app-header-user-actions {
  .app-header-user-actions-menu {
    :nth-child(n) {
      color: var(--dark-color);
      font-family: var(--primary-font);
      font-weight: var(--font-weight-medium);
    }
  }
}
