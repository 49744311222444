.finca-kpi-content {
  color: var(--dark-color);
  font-family: var(--secondary-font);
  &[data-align='center'] {
    .ant-space-item {
      text-align: center;
    }
  }
  .finca-kpi-value {
    font-size: 30px;
  }
  .finca-kpi-subvalue {
    font-size: 14px;
    margin-left: 8px;
  }
  .finca-kpi-description {
    font-size: 16px;
  }
}

.finca-kpi-card {
  display: flex;
  height: 100%;
  &[data-align='center'] {
    justify-content: center;
    align-items: center;
  }
}
