.units-add-drawer {
  .ant-drawer-header {
    .ant-drawer-title {
      display: flex;
      align-items: center;
      font-family: var(--primary-font);
      font-weight: var(--font-weight-medium);
      color: var(--primary-color-dark);
      font-size: 14px;
      .anticon {
        font-size: 24px;
        margin-right: 10px;
      }
    }
    .ant-drawer-close {
      color: var(--primary-color-dark);
      font-size: 18px;
    }
  }
  /* Vertical centered drawer content: */
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 0;
    .units-add-form-wrapper {
      padding: 0 10%;
      overflow: auto;
    }
  }
}
