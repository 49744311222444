.units-sync-modal {
  .unit-sync-steps-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 24px 8px 8px;
    padding: 8px 24px;
    background-color: #fafafa;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    height: 120px;
    .step-description {
      text-align: center;
    }
    .img-preview {
      margin-right: 8px;
      border-radius: 8px;
    }
  }
}
