.ant-spin.finca-client-loading {
  padding: 2%;
  width: 100%;
}
.finca-client-container {
  padding: 5%;
  padding-top: 2%;
  .est-title {
    margin-top: 2%;
    font-size: 30px;
    font-family: var(--primary-font);
    color: var(--dark-color);
  }
  .est-container-title {
    margin-bottom: 10px;
    height: 35px;
    font-size: 14px;
    font-family: var(--primary-font);
    font-weight: var(--font-weight-medium);
    color: var(--dark-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .est-data {
    margin-top: 28px;
    margin-bottom: 28px;
  }
  .est-users {
    .est-users-add span {
      font-size: 10px;
      font-family: var(--primary-font);
      font-weight: var(--font-weight-medium);
      text-decoration: underline;
    }
  }
}
