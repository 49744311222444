.finca-menu-dropdown {
  .actions-menu-button {
    color: var(--primary-color);
    font-size: 24px;
  }
}
.finca-menu-dropdown-overlay.ant-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      padding: 10px 30px;
      font-family: var(--secondary-font);
      font-size: 12px;
      .anticon.ant-dropdown-menu-item-icon {
        color: var(--primary-color);
        font-size: 16px;
      }
    }
  }
}
.finca-dropdown-menu-trigger {
  &:hover {
    cursor: pointer;
  }
  &[data-disabled='true'] {
    cursor: not-allowed;
  }
}
